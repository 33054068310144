/*
 * @Author: Zhouql
 * @Date: 2020-06-24 15:30:41
 * @LastEditTime: 2021-06-21 11:44:55
 * @LastEditors: pengyu
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\myFolder\folderBtn\folderlisBtn.js
 */
export default {
  methods: {
    /**
     * @description: 列表单行按钮
     * @param {rowData} 行数据
     * @return:
     */
    folderlisBtn(rowData) {
      const { fileName, sourceType } = rowData;
      const suffix = this.getSuffix(fileName);
      const btns = [
        {
          id: "edit",
          name: "编辑",
          icon: "bianji-liebiao",
          iconColor: "#26c393"
        },
        {
          id: "download",
          name: "下载",
          icon: "xiazai1",
          iconColor: "#58cb7e"
        },
        {
          id: "delete",
          name: "删除",
          icon: "shibai",
          iconColor: "#f76b6b"
        }
      ];
      // 支持在线编辑的文档类型
      if (["docx", "pptx", "xlsx"].includes(suffix)) {
        btns.splice(1, 0, {
          id: "editOnline",
          name: "在线编辑",
          icon: "bianji-liebiao",
          iconColor: "#f7bf28"
        });
      }

      // 根据 状态 筛选所需按钮
      function remove(arr, ids) {
        ids.forEach((itm) => {
          const idx = arr.findIndex((a) => a.id === itm);
          if (idx > -1) {
            arr.splice(idx, 1);
          }
        });
      }
      // 状态为2
      if (sourceType === 2) {
        remove(btns, ["edit"]);
      }

      return btns;
    },
    /**
     * @description: 获取文件名后缀
     * @param {*} file 文件名
     */
    getSuffix(file) {
      const index = file.lastIndexOf(".") + 1;
      const len = file.length;
      let suffix = file.substring(index, len);
      suffix = suffix.split("&")[0];
      return suffix.toLowerCase();
    }

  }
};
