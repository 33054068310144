var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "hand-sync",
      attrs: { visible: _vm.visiable, title: "手动同步", width: "700px" },
      on: {
        "update:visible": function ($event) {
          _vm.visiable = $event
        },
        close: function ($event) {
          _vm.visiable = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-form",
            {
              ref: "syncForm",
              attrs: {
                model: _vm.syncForm,
                "label-width": "60px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "同步到" } },
                [
                  _c("en-select", {
                    attrs: {
                      placeholder: "请选择对应文档分类",
                      mode: "tree",
                      props: _vm.defaultProps,
                      data: _vm.syncTreeData,
                      "check-mode": "siblings",
                      filterable: "",
                    },
                    on: { change: _vm.cousData },
                    model: {
                      value: _vm.syncForm.classifyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.syncForm, "classifyName", $$v)
                      },
                      expression: "syncForm.classifyName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "en-button",
            {
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }