var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "upload-file",
      attrs: { visible: _vm.visiable, title: "上传文件", width: "700px" },
      on: {
        "update:visible": function ($event) {
          _vm.visiable = $event
        },
        close: function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-form",
            {
              ref: "nForm",
              attrs: { model: _vm.uploadForm, "label-position": "left" },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        name: "storage_file",
                        action: _vm.urlParam.server,
                        data: _vm.uploadParams,
                        "on-change": _vm.handleChange,
                        "on-exceed": _vm.handleExceed,
                        "before-upload": _vm.handleBeforeUpload,
                        "on-success": _vm.handleSuccess,
                        multiple: true,
                        limit: 5,
                        "file-list": _vm.fileList,
                        "show-file-list": false,
                        "auto-upload": false,
                      },
                    },
                    [
                      _c(
                        "en-button",
                        { attrs: { size: "small", icon: "add" } },
                        [_vm._v(" 选择文件 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "uploadfile-main" },
                    [
                      _vm.fileList.lenght > 0
                        ? _c("span", [_vm._v(_vm._s(_vm.fileList))])
                        : _vm._e(),
                      _vm._l(_vm.fileList, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.uid,
                            staticClass: "file-item",
                            class: {
                              "s-success": item.status === "success",
                              "s-fail": item.status === "fail",
                              "s-uploading": item.status === "uploading",
                            },
                          },
                          [
                            item.status === "uploading"
                              ? _c("el-progress", {
                                  attrs: {
                                    "text-inside": true,
                                    "stroke-width": 32,
                                    percentage: item.percentage,
                                  },
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "item-main" }, [
                              _c("div", {
                                staticClass: "status-main",
                                style: {
                                  width: Math.floor(item.percentage) + "%",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "file-main" },
                                [
                                  _c("en-icon", {
                                    staticClass: "file-icon",
                                    attrs: {
                                      name: _vm.suffix(item.name).iconName,
                                      color: _vm.suffix(item.name).iconColor,
                                      size: "small",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { attrs: { title: "item.name" } },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "file-state" }, [
                                item.status === "ready"
                                  ? _c("div", { staticClass: "canceled-txt" }, [
                                      _vm._v("待上传"),
                                    ])
                                  : _vm._e(),
                                item.status === "success"
                                  ? _c("div", { staticClass: "canceled-txt" }, [
                                      _vm._v("上传成功"),
                                    ])
                                  : _vm._e(),
                                item.status === "uploading"
                                  ? _c("div", { staticClass: "canceled-txt" }, [
                                      _vm._v(
                                        "上传中：" +
                                          _vm._s(Math.floor(item.percentage)) +
                                          "%"
                                      ),
                                    ])
                                  : _vm._e(),
                                item.status === "fail"
                                  ? _c("div", { staticClass: "canceled-txt" }, [
                                      _vm._v("上传失败"),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cancel-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteFile(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("en-icon", {
                                      attrs: {
                                        name: "shaixuantiaojianshanchu21",
                                        size: "small",
                                        color: "#a9b5c6",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "txts" }, [
            _vm._v("已选"),
            _c("span", { staticStyle: { color: "#3e90fe" } }, [
              _vm._v(_vm._s(_vm.fileList.length)),
            ]),
            _vm._v("条文件"),
          ]),
          _c(
            "en-button",
            { staticClass: "sure-btn", on: { click: _vm.submitUpload } },
            [_vm._v(" 上传 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }