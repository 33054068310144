<template>
    <en-dialog
      :visible.sync="visiable"
      append-to-body
      :title="`${classifyItem.id?isMove?'移动':'编辑':'新增'}文件夹`"
      width="700px"
      class="add-class"
      @close="visiable=false"
    >
      <el-form
        ref="formClass"
        label-width="72px"
      >
        <el-form-item label="文件夹名称" size="small" v-if='!isMove'>
          <el-input v-model="formClass.className" placeholder="请输入文件夹名称"/>
        </el-form-item>
        <el-form-item :label="isMove?'移动到':'所属上级'" size="small">
          <en-select
            mode="tree"
            v-model="superiorId"
            data-mode="data"
            placeholder="请选择"
            :props="defaultProps"
            :data="superiorList"
            :check-limit="1"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            @change="selectChange"
        >
        </en-select>
        </el-form-item>
        <!-- <el-form-item label="颜色设置">
          <en-color-setting
          v-model="color"
          class="en-color-setting"
        />
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <en-button @click="submit(classifyItem.id?'myFolderUpdateFolder':'myFolderAddFolder')">
          保存
        </en-button>
      </div>
    </en-dialog>
</template>

<script>
import { documentService } from "@/api/documentManagement";
import { foldersService } from "@/api/folders";

export default {
  props: {

    isMove: {
      type: Boolean,
      default: false
    },
    classifyItem: {}
  },
  data() {
    return {
      visiable: false,
      defaultProps: {
        children: "nodes",
        label: "text",
        value: "id"
        // disabled: true
      },
      formClass: { className: "", parentId: "", color: "" },
      // 所属上级
      superiorList: [],
      superiorId: null,
      color: "#26c393"
    };
  },
  watch: {
    visiable(val) {
      this.queryMyFolderFilesTree();
      if (val) {
        this.formClass.className = this.classifyItem.name;
        this.formClass.parentId = this.classifyItem.parentId;
        this.formClass.id = this.classifyItem.id;
        if (!this.isMove) {
          this.superiorId = {
            id: this.classifyItem.parentId || undefined,
            name: this.classifyItem.parentName || undefined
          };
        } else {
          this.superiorId = {
            id: this.classifyItem.parentId !== "0" ? this.classifyItem.parentId : undefined,
            name: this.classifyItem.parentName || undefined
          };
        }
      }
    }
  },
  methods: {
    // 查询所属上级
    async queryMyFolderFilesTree(id) {
      const res = await foldersService.queryMyFolderFilesTree({ id });
      this.superiorList = res;
    },

    /**
     * @description: 指定节点是否为叶子节点
     * @param data 节点数据
     * @return:
     */
    isLeaf(data) {
      return data.hasChildren === "001";
    },
    /**
     * @description: 树 异步加载子节点
     * @param node
     * @param resolve
     */
    async loadNode(node, resolve) {
      const { data } = node;
      const { hasChildren, id } = data;
      if (hasChildren === "000") {
        // 有子项
        const rsp = await documentService.queryRulesClassifyTree({ id });
        resolve(rsp);
      } else {
        resolve([]);
      }
    },
    selectChange(val) {
      console.log(val);
      this.formClass.parentId = val.id;
    },
    // 提交
    /**
     * @description: 新增&修改文件夹
     * @param {*} type:myFolderUpdateFolder修改 myFolderAddFolder:新增
     * @return {*}
     */
    async submit(type) {
      try {
        this.loading = true;
        const rsp = await foldersService[type]({
          name: this.formClass.className,
          parentId: this.formClass.parentId,
          id: this.classifyItem.id || undefined,
          operateType: this.isMove ? 2 : undefined
        });
        this.$message.success(`${type === "myFolderAddFolder" ? "新增" : "移动"}文件夹成功`);
        this.$emit("success", rsp);
        this.visiable = false;
      } catch (e) {
        this.visiable = true;
        throw e;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add-class{
  text-align: left;
  .el-select{
    width: 100%;
  }
  .el-form{
    margin-top: 10px;
  }
  .el-form-item{
    margin-bottom: 10px;
  }
  .el-dialog__footer{
    .en-button{
      width: 72px;
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>
