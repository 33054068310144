<!--
 * @Author: Zhouql
 * @Date: 2020-06-29 18:01:53
 * @LastEditTime: 2021-05-24 12:00:50
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\myFolder\alert\handsync.vue
-->
<template>
  <en-dialog
    :visible.sync="visiable"
    title="手动同步"
    class="hand-sync"
    width="700px"
    @close="visiable=false"
  >
    <div class="main">
      <el-form
        ref="syncForm"
        :model="syncForm"
        label-width="60px"
        label-position="left"
      >
        <el-form-item label="同步到">
           <en-select
            v-model="syncForm.classifyName"
            placeholder="请选择对应文档分类"
            mode="tree"
            :props="defaultProps"
            :data="syncTreeData"
            check-mode="siblings"
            @change="cousData"
            filterable
          >
          </en-select>
        </el-form-item>

      </el-form>
    </div>
    <div slot="footer">
      <en-button @click="submitForm()">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
export default {
  props: {
    treeData: { },
    formData: {}
  },
  data() {
    return {
      visiable: false,
      syncForm: { classifyName: "" },
      syncTreeData: [],
      id: "",
      // 分类propos
      defaultProps: {
        children: "nodes",
        label: "name",
        value: "id"
      }
    };
  },
  watch: {
    visiable(val) {
      if (val) {
        this.syncTreeData = this.treeData;
        this.syncForm.classifyName = this.formData.classifyName;
      }
    }
  },
  methods: {
    cousData(data) {
      this.id = data;
    },
    submitForm() {
      this.$emit("classId", this.id);
      this.visiable = false;
    }

  }
};
</script>

<style lang="scss" scoped>
.hand-sync{
  text-align: left;
  .main{
    min-height: 100px;
  }
  .en-select {
    width: 100%;
  }
  .aaa{
    text-align: left;
  }
}
</style>
