<!--
 * @Author: Zhouql
 * @Date: 2020-04-21 11:33:38
 * @LastEditTime: 2021-08-20 10:58:17
 * @LastEditors: zhulin
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\myFolder\alert\editFile.vue
 -->
<template>
  <en-dialog
    :visible.sync="visiable"
    title="编辑文件"
    class="edit-file"
    width="700px"
    @close="visiable=false"
  >
    <div class="main">
      <el-form
        ref="fileForm"
        :model="fileForm"
        label-width="80px"
        label-position="left"
      >
        <el-form-item
          label="文件名称"
          :class="isSingle?'show':'' "
        >
          <el-input v-model="fileForm.fileName" maxlength="20" placeholder="输入文件名称" @focus="focus($event)">
          </el-input>
        </el-form-item>
        <el-form-item :label="'所属文件夹'" size="small">
          <en-select
            mode="tree"
            v-model="superiorId"
            data-mode="data"
            placeholder="请选择"
            :props="defaultProps"
            :data="superiorList"
            :check-limit="1"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            @change="selectChange"
        >
        </en-select>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <en-button @click="submitForm()">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import { foldersService } from "@/api/folders";

export default {
  props: {
    isSingle: {
      type: Boolean,
      default: false
    },
    fileFormItem: { }
  },
  data() {
    return {
      visiable: false,
      fileForm: { fileName: "", fileId: "" },
      superiorList: [],
      superiorId: null,
      defaultProps: {
        children: "nodes",
        label: "text",
        value: "id"
        // disabled: true
      }
    };
  },
  watch: {
    visiable(val) {
      if (val) {
        this.queryMyFolderFilesTree();
        this.fileForm.fileName = this.fileFormItem.fileName;
        this.fileForm.fileId = this.fileFormItem.id;
        this.superiorId = (this.fileFormItem.folderId && this.fileFormItem.folderId !== "0" && this.fileFormItem.folderId) || null;
      }
    }
  },
  methods: {
    focus(event) {
      event.currentTarget.select();
    },
    // 查询所属上级
    async queryMyFolderFilesTree(id) {
      const res = await foldersService.queryMyFolderFilesTree({ id });
      this.superiorList = res;
    },
    selectChange(val) {
      console.log(val);
      this.fileForm.folderId = val.id;
    },
    /**
    * 提交
    */
    submitForm() {
      this.$parent.submitForm();
      this.visiable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item{
  margin-bottom:10px;
}
.edit-file {
  text-align: left;

  .el-select {
    width: 100%;
  }
  .show {
    display: none;
  }
}
</style>
