<!--
 * @Author: Zhouql
 * @Date: 2020-04-21 17:03:33
 * @LastEditTime: 2021-11-05 12:05:58
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user-document-management\src\views\folders\alert\uploadFile.vue
 -->
<template>
  <en-dialog
    :visible.sync="visiable"
    title="上传文件"
    class="upload-file"
    width="700px"
    @close="close()"
  >
    <div class="main">
      <el-form
        ref="nForm"
        :model="uploadForm"
        label-position="left"
      >
        <el-form-item>
          <el-upload
            class="upload-demo"
            name="storage_file"
            ref="upload"
            :action="urlParam.server"
            :data="uploadParams"
            :on-change="handleChange"
            :on-exceed="handleExceed"
            :before-upload="handleBeforeUpload"
            :on-success="handleSuccess"
            :multiple="true"
            :limit="5"
            :file-list="fileList"
            :show-file-list="false"
            :auto-upload="false"
          >
            <en-button
              size="small"
              icon="add"
            >
              选择文件
            </en-button>
          </el-upload>
          <div class="uploadfile-main">
            <span v-if="fileList.lenght>0">{{fileList}}</span>
            <!--'当上传成功时 添加 upload-success '-->
            <div class="file-item"
            v-for="item in fileList"
            :key="item.uid"
            :class="{
              's-success': item.status === 'success',
              's-fail': item.status === 'fail',
              's-uploading': item.status === 'uploading'
              }">
              <el-progress
                :text-inside="true"
                :stroke-width="32"
                v-if="item.status === 'uploading'"
                :percentage="item.percentage"
              >
              </el-progress>
              <div class="item-main">
                <div class="status-main" :style="{width:Math.floor(item.percentage)+'%'}"></div>
                <div class="file-main">
                  <en-icon :name="suffix(item.name).iconName" :color="suffix(item.name).iconColor"  size="small" class="file-icon"></en-icon>
                  <span title="item.name">{{item.name}}</span>
                </div>
                <div class="file-state">
                  <div class="canceled-txt" v-if="item.status === 'ready'">待上传</div>
                  <div class="canceled-txt" v-if="item.status === 'success'">上传成功</div>
                  <div class="canceled-txt" v-if="item.status === 'uploading'">上传中：{{Math.floor(item.percentage)}}%</div>
                  <div class="canceled-txt" v-if="item.status === 'fail'">上传失败</div>
                  <div class="cancel-btn" @click="deleteFile(item)">
                    <en-icon name="shaixuantiaojianshanchu21" size="small" color="#a9b5c6"></en-icon>
                  </div>
                </div>
              </div>

            </div>
        </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="bottom">
      <div class="txts">已选<span style="color:#3e90fe">{{fileList.length}}</span>条文件</div>
      <en-button class="sure-btn"  @click="submitUpload">
        上传
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { foldersService } from "@/api/folders";
import folderComm from "@/mixins/myFolder/folderComm.js";
import { getFileTypeList } from "@/tools/folderVarCommon.js";
// 获取同步的树,模板分类
export default {
  name: "uploadFile",
  mixins: [folderComm],
  computed: {
    ...mapGetters(["userInfo", "urlParam"]),
    readyFiles() {
      return this.fileList.filter((item) => item.status === "ready");
    }
  },
  props: {
    folderId: [String, Number]
  },
  data() {
    return {
      visiable: false,
      uploadForm: { },
      // 上传文件列表
      fileList: [],
      fileLis: [],
      uploadParams: {}, // 上传参数
      files: []
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.bUrl = from.path; // 获取返回路径
    next();
  },

  mounted() {
    this.uploadParams = {
      appKey: this.urlParam.appKey,
      token: this.urlParam.token,
      ts: this.urlParam.ts
    };
    this.getFilesList(); // 获取fileslist
  },
  watch: {
    fileList: {
      handler(newFileList) {
        console.log(newFileList, "newFileList");
      },
      deep: true
    }
  },
  methods: {
    // 保存上传文件
    async savaUploadFile(response, data) {
      try {
        this.loading = true;
        await foldersService.saveUploadRecords({
          fileName: data.name,
          fileUrl: response.data.fileCode,
          attachmentSize: data.size,
          extension: this.getSuffix(data.name),
          folderId: this.folderId
        });
        this.$emit("uploadFile");
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 获取files列表
    getFilesList() {
      const fileList = getFileTypeList();
      this.files = fileList.map((item) => item.text);
    },
    /**
      * 上传文件
     */
    submitUpload() {
      if (!this.readyFiles.length) {
        this.$message.warning("没有待上传的文件");
        return;
      }
      this.$refs.upload.submit();
    },

    handleBeforeUpload(file) {
      console.log(file);
      return true;
    },
    handleExceed() {
      this.$message.warning("最多上传5个文件");
    },
    // 上传成功回调事件
    handleChange(file, fileList) {
      console.log("changeFileList", file, fileList);
      const errors = [];
      const newFileList = fileList.slice().filter((item) => {
        if (item.size > 1024 * 1024 * 5) {
          errors.push("上传单个文件大小不能超过 5MB!");
          return false;
        }
        if (this.files.includes(this.getSuffix(item.name)) === false) {
          errors.push(`请上传${this.files}格式的文件!`);
          return false;
        }
        return true;
      });
      if (errors.length >= 1) {
        console.log("error", newFileList);
        this.$message.error(errors.join("\r\n"));
      }
      this.fileList = newFileList;
    },
    // 上传成功
    handleSuccess(response, file, fileList) {
      console.log("success", response, file);
      this.$nextTick(() => { // nextTick 等待 change 执行完之后
        console.log("successNexTick", response, file);
        let checkResult = true;
        if (response.code !== "0") {
          this.$message.error(response.msg);
          checkResult = false;
        }
        if (!checkResult) {
          this.fileList = fileList.filter((item) => item.uid === file.uid);
        }
        this.savaUploadFile(response, file);
      });
    },
    // 删除上传文件
    deleteFile(data) {
      console.log(data);
      this.fileList = this.fileList.filter((e) => e.uid !== data.uid);
    },

    // 关闭弹窗
    close() {
      this.visiable = false;
      this.fileList = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-file {
  text-align: left;
  .main {
    min-height: 215px;
  }
  .el-select {
    width: 100%;
  }
  .sure-btn {
    height: 32px;
    width: 72px;
  }
  .uploadfile-main {
    margin-top: 15px;
    .file-item {
      position: relative;
      height: 34px;
      border-radius: 5px;
      width: 100%;
      background: #f1f4fa;
      margin-bottom: 10px;
    }
    .item-main {
      position: relative;
      display: flex;
      padding: 0 15px;
      height: 34px;
      line-height: 34px;
      .status-main{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 34px;
        line-height: 34px;
        border-radius: 5px;
      }
    }
    .file-main {
      width: 450px;
      height: 34px;
      line-height: 34px;
      cursor: pointer;
      z-index: 9;
      span {
        margin-left: 20px;
      }
      .file-icon {
        position: absolute;
        left: 10px;
        top: 7px;
      }
    }
    .file-state {
      flex: 1;
      text-align: right;
      z-index: 9;
      div {
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
      .fail-txt {
        font-size: 12px;
        color: #ff5d5d;
      }
      .canceled-txt {
        font-size: 12px;
        color: #c7c7c7;
      }
      .cancel-btn{
        cursor: pointer;
      }
    }
  }
  .el-progress{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  /deep/ .el-progress-bar__inner{
    background: #e9f9f4;
    border-radius: 3px;
  }
  /deep/ .el-progress-bar__outer{
    border-radius: 3px;
  }
  .s-success{
    .status-main{
      // width: 100%;
      background:#f5f8fd;
    }

  }
  .s-fail{
    .status-main{
      // width: 40%;
      background: #f5eaed;
    }
    .canceled-txt{
      color: #f54646;
    }
  }
  .s-uploading{
    .status-main{
      background: #e3eef9;
    }
  }
  .bottom{
    .txts{
      float:left;
      font-size: 12px;
      line-height: 32px;
    }
  }
}
</style>
