<!--
 * @Author: Zhouql
 * @Date: 2020-06-22 17:40:58
 * @LastEditTime: 2024-02-18 15:25:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\myFolder\index.vue
-->
<template>
  <div class="tiling-main">
    <div class="equal-side">
        <div class="equal-sidetopbar">
          <div class="pull-left">
            <div class="col-tit">
              我的文件夹
            </div>
          </div>
          <div class="pull-right-top">
            <en-icon name="tianjia-danchuang" class='add-folder' size="small"  @click.native="addClassify"/>
          </div>
        </div>
        <div class="equal-sidecentbar" v-en-loading='menuLoading'>
          <div class="side-list">
          <en-file-select-board
              :data="menuData"
              node-key="id"
              highlight-current
              ref="filtmenu"
              accordion
              expand-icon="wendangleixing-wendangguanli"
              collpase-icon="wendangleixing-wendangguanli"
              :props="defaultProps"
              @check="selectItem"
              @node-click="selectItem"
              @node-select="selectItem"
              :render-content="renderContent"
            >
            </en-file-select-board>
          </div>
        </div>
    </div>
    <div class='equal-right'>
      <en-title-card >
        <div slot="left">
          <div class="volume">
            <span class="tits">容量</span>
            <el-progress
              :show-text="false"
              :stroke-width="10"
              :percentage="fileSizeRatio"
            ></el-progress>
            <span class="max-volume">{{ totalFileSize }}M/200M</span>
          </div>
        </div>
        <div slot="right">
          <div class="btn-group">
            <!--'下载上传图标暂未定 后期需修改'-->
            <div class="sync-btn" @click="entryEmail()" v-if="hasPrower('073')">
              <en-icon name="xieyoujian-wenjianjia" size="small" color="#26c393">
              </en-icon>
              <span>写邮件</span>
            </div>
            <div class="sync-btn" @click="multipleSync()">
              <en-icon name="shoudongtongbu" size="small" color="#26c393">
              </en-icon>
              <span>手动同步</span>
            </div>
            <div class="share-btn" @click="syncRule()" >
              <en-icon name="tongbu" size="small" color="#b3b7bf" >
              </en-icon>
              <span>同步</span>
            </div>
            <div class="down-btn" @click="multipleDownload()">
              <en-icon name="xiazai" size="small" color="#a9b5c6">
              </en-icon>
              <span>下载</span>
            </div>
            <div class="upload-btn" @click="uploadFile()">
              <en-icon name="shangchuan" size="small" color="#26c393">
              </en-icon>
              <span>上传</span>
            </div>
            <div class="delete-btn" @click="multipleDeleteFile()">
              <en-icon name="shanchu" size="small" color="#f76b6b">
              </en-icon>
              <span>删除</span>
            </div>
            <div class='dropdown-add' >
              <el-dropdown >
                <span class="el-dropdown-link">
                  <en-button icon="tianjia-anniutoubu" >添加</en-button>
                </span>
                <el-dropdown-menu slot="dropdown" class='dropdown-list'>
                  <el-dropdown-item @click.native="createFile(1)">Word文档</el-dropdown-item>
                  <el-dropdown-item @click.native="createFile(2)">Excel表格</el-dropdown-item>
                  <el-dropdown-item @click.native="createFile(3)">PowerPoint文稿</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </en-title-card>
      <div class="table-wrap">
        <en-table
            ref="enTable"
            :data="tableData"
            :loading="loading"
            @filter-sort-change="handleFilterSortChange"
            @select-change="handleSelectChange"
            :height-config="{ bottomHeight: 15 }"
            :select-config="{ reserve: true }"
            :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }"
          >
            <en-table-column type="index-selection" >

            </en-table-column>
            <en-table-column
              title="文件名称"

              field="fileName"
              width='280'
              :filter-sort-config="{filterable:true, sortable:true}"
              :filter-render="{name: 'ElInput', attrs: {placeholder: '请输入名称...'}}"
            >
              <!--自定义单元格-->
              <template v-slot="{row}" >
                <en-icon
                  :name="suffix(row.fileName).iconName"
                  :color="suffix(row.fileName).iconColor"
                  size="small"
                ></en-icon>
                <span @click="columnClick(row)" class='file-title'>
                  {{ row.fileName }}
                </span>
              </template>
            </en-table-column>
            <!-- <en-table-column
              title="所属分类"
              field=""
              width="100px"
            >
            </en-table-column> -->
            <en-table-column
              title="来源"
              field="busName"
              width="100px"
              :filter-sort-config="{filterable:true, sortable:true}"
              :filter-render="{name: 'ElInput', attrs: {placeholder: '请输入来源...'}}"
            >
            <template v-slot="{row}">
              <div>
                  {{row.sourceType===1?'自主上传':row.busName}}
              </div>
            </template>
            </en-table-column>

            <en-table-column
              title="修改时间"
              field="modifyTime"
              width="160px"
              :filter-sort-config="{filterable:true, sortable:true}"
              :filter-render="{
                name: 'EnDatePicker',
                props:{type: 'daterange', valueFormat:'yyyy-MM-dd',startPlaceholder: '请选择开始时间',endPlaceholder: '请选择结束时间', unlinkPanels: true}
              }"
            >
            </en-table-column>
            <en-table-column
              title="状态"
              field="status"
              width="100"
              :filter-sort-config="{filterable:true, sortable:true}"
              :filter-render="{name: 'ElInput', attrs: {placeholder: '请输入状态...'}}"
            >
              <!--自定义单元格-->
              <template v-slot="{row}">
                <div v-if="row.status===1">
                  <el-tag size="small">未同步</el-tag>

                </div>
                <div v-else>
                  <el-tag type="success" size="small">已同步</el-tag>

                </div>
              </template>
            </en-table-column>
            <en-table-column
              title="上传时间"
              field="createTime"
              width="160px"
              :filter-sort-config="{filterable:true, sortable:true}"
              :filter-render="{
                name: 'ElDatePicker',
                props:{type: 'daterange', valueFormat:'yyyy-MM-dd',startPlaceholder: '请选择开始时间',endPlaceholder: '请选择结束时间', unlinkPanels: true}
              }"
            >
            </en-table-column>
            <en-table-column
              title="创建人"
              field="createName"
              width="100px"
              :filter-sort-config="{filterable:true, sortable:true}"
              :filter-render="{name: 'ElInput', attrs: {placeholder: '请输入创建人...'}}"
            >
            </en-table-column>
            <en-table-column
              title="所属人"
              field="ownerName"
              width="100px"
              :filter-sort-config="{filterable:true, sortable:true}"
              :filter-render="{name: 'ElInput', attrs: {placeholder: '请输入所属人...'}}"
            >
            </en-table-column>
            <en-table-column
              title="文件大小"
              field="attachmentSize"
              width="160px"
              :filter-sort-config="{filterable:true, sortable:true}"
              :filter-render="{name: 'EnInputRange', props:  {startPlaceholder: '最小值',endPlaceholder:'最大值'}}"
            >
              <!--自定义单元格-->
              <template v-slot="{ row, column}">
                <div>
                  {{ parseFloat( row[column.field] / 1024 / 1024).toFixed(2) + "M" }}
                </div>
              </template>
            </en-table-column>

            <template #hoverRow="{row, rowIndex}">
              <en-expand-buttons
                :min-number="3"
                :data="folderlisBtn(row)"
                @button-click="handleButtonClick(row,rowIndex, arguments[1], arguments[0])"
              />
            </template>
             <template #paginationLeft>
                <div class="selection">
                  已选: <span>{{selRow.length}}</span> 条数据
                  <en-button text type="primary" @click="clearSelection">清空</en-button>
                </div>
            </template>
        </en-table>
      </div>

      <!--'编辑名称弹窗'-->
      <editFile ref="editFileAlert"
        :is-single="isSingle"
        :file-form-item="fileFormItem"></editFile>
      <!--'上传文件弹窗'-->
      <uploadFile ref="uploadFileAlert" @uploadFile="getFile" :folderId='folderId'></uploadFile>
      <!--'手动同步'-->
      <handSync ref="handSyncAlert"  :tree-data="treeData" :formData="formData" @classId="postHandSync"></handSync>

    </div>
    <!-- 新增文件夹&移动文件夹 -->
    <edit-folder ref="editFolderDialog" :classify-item="folderItem" @success="classifySuccess" :isMove='editMode=="moving"'></edit-folder>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "en-js";
import { foldersService } from "@/api/folders";
import folderlisBtn from "@/mixins/myFolder/folderlisBtn.js"; // 获取单行按钮
import folderComm from "@/mixins/myFolder/folderComm.js";// 获取同步的树,模板分类
import { downFileApi } from "@/api/downFileApi";
import {
 openOfficeOnline, xhrDownload, randomNum, verifyMenuPower
} from "@/tools/util";
// import { getFileUrl } from "@/tools/getFileUrl";
import editFile from "./alert/editFile.vue"; // 编辑文件名弹窗
import uploadFile from "./alert/uploadFile.vue";
// 上传文件弹窗
import handSync from "./alert/handSync.vue";
// 手动同步
import editFolder from "./alert/editFolder.vue"; // 新增&编辑文件夹
// 编辑弹窗
export default {
  name: "myFolder",
  mixins: [folderlisBtn, folderComm],
  components: {
    editFile, uploadFile, handSync, editFolder
  },
  data() {
    return {
      label: "序号",
      sortData: {},
      filterData: [],
      tableData: [], // 列表数据
      menuData: [], // 文件夹树
      menuLoading: null,
      dialogFormVisible: false,
      loading: false,
      pageModel: { // 分页
        pageSize: null,
        pageNo: null,
        total: null,
        totalPages: null
      },
      editMode: "",
      curFolderMenu: null, // 当前所在文件夹
      defaultProps: {
        children: "nodes",
        label: "text",
        value: "id"
        // disabled: true
      },
      folderItem: {},
      statusfilterValue: "", // 过滤状态的条件值
      countStorage: null, // 文件大小总值
      useStorage: null, // 文件大小总值
      selIds: [], // 多选中的ID
      selRow: [], // 多选中的行内容
      isSingle: false, // 是否为单个编辑
      fileFormItem: {}, // 编辑数据
      treeData: [], // 树数据
      templateClass: {}, // 业务建模分类
      syncId: null, // 手动同步ID
      formData: {}
    };
  },

  mounted() {
    this.queryMyFolderFilesTree();
    Promise.all([
    // 调用查询列表数据方法
      this.queryFolderList(),
      this.queryStorage(),
      this.getSyncRule(),
      this.getTemplateClassify() // 模板分类
    ]).then(() => {
      this.treeData = this.syncList;
    });
  },
  computed: {
    // 引入urlParam
    ...mapGetters({
      $urlParam: "urlParam"
    }),
    dataList() {
      return this.$store.getters["main/menuList"];
    },

    statusfilterConfig() {
      return {
        sortable: true,
        filterable: true,
        filterValueMethod: () => ({
          value: this.statusfilterValue,
          valueText: this.statusfilterValue === 1 ? "未同步" : "同步",
          notFormat: true
        })
      };
    },
    // 文件大小总值
    totalFileSize() {
      const total = parseFloat(this.useStorage / 1024 / 1024).toFixed(2);
      return total;
    },
    // 文件大小总比值
    fileSizeRatio() {
      return ((this.totalFileSize * 100) / 200);
    },
    folderId() {
      return this.curFolderMenu?.id || 0;
    }
  },

  methods: {
    clearSelection() {
      this.selRow = [];
      this.selIds = [];
      this.$refs.enTable?.setAllSelection(false);
    },
    indexMouseEnter(e) {
      console.log(e);
    },
    previewFile(item) {
      console.log(item);
      const operType = 1;
      const suffix = this.getSuffix(item.fileName);
      if (["docx", "xlsx", "pptx"].includes(suffix)) {
        openOfficeOnline(operType, suffix, item.fileUrl);
      } else {
        this.$message.warning("暂不支持该类型");
      }
    },
    columnClick(file) {
      this.previewFile(file);
    },
    hasPrower(code) {
      return verifyMenuPower(this.dataList, code);
    },
    renderContent(h, { node, data }) {
      console.log(node, data);
      return (
          <span class="custom-tree-node " onMouseleave={(event) => this.toggleMenu(event, data, false)}>
          {!node.childNodes.length && node.level < 3 ? <span><en-icon name="wendangleixing-wendangguanli" size='10' class='custom-file-icon'></en-icon></span> : ""}
          { this.folderItem?.id === node.value && this.editMode === "rename"
            ? <el-input class='name-input' v-model={this.folderItem.text} size='mini' onBlur={(event) => { this.reNameSucess(event, node); }}></el-input>
             : <span class='label' >{node.label }</span>}
            <div class="pull-right btns ">
            <en-icon name="bianji1" class="btns-icon" nativeOnMouseover={(event) => { this.toggleMenu(event, data, true); }} >
            </en-icon>
            <en-collapse-area>
              <div class="btns-list-container" v-show={data.showMenu} onMouseleave={(event) => { this.toggleMenu(event, data, false); }} >
                <div class="btns-list" >
                  <div class="btns-item" onClick={(event) => { this.reSetName(event, data); }} >
                    重命名
                  </div>
                  <div class="btns-item" onClick={(event) => { this.moveTo(event, data); }} >
                    移动到
                  </div>
                  <div class="btns-item" onClick={(event) => { this.deleteClassIfy(event, data); }} >
                    删除
                  </div>
                </div>
              </div>
            </en-collapse-area>
          </div>
          </span>);
    },
    toggleMenu(event, data, flag) {
      event.stopPropagation();
      console.log(data);
      this.$set(data, "showMenu", flag);
    },
    // 重命名
    reSetName(event, item) {
      console.log(event, item);
      event.stopPropagation();
      this.folderItem = item;
      this.editMode = "rename";
      // 自动获取焦点
      this.$nextTick(() => {
        document.querySelector(".name-input input").focus();
      });
    },
    // 重命名输入框失去焦点
    async reNameSucess(event) {
      event.stopPropagation();
      try {
        await foldersService.myFolderUpdateFolder({
          name: this.folderItem.text,
          operateType: 1,
          id: this.folderItem.id
        });
        this.$message.success("重命名成功");
        this.visiable = false;
      } catch (e) {
        this.$message.error(e);
        throw e;
      } finally {
        this.folderItem = {};
      }
    },
    /**
     * 新增/编辑 分类
     */
    addClassify(event, item) {
      event.stopPropagation();
      this.editMode = "add";
      if (item) {
        this.folderItem = { ...item };
      } else {
        this.folderItem = {};
      }
      this.$refs.editFolderDialog.visiable = true;
    },
    // 开启移动到弹框
    moveTo(event, item) {
      event.stopPropagation();
      this.editMode = "moving";
      this.folderItem = item;
      console.log(item);
      this.$refs.editFolderDialog.visiable = true;
    },
    // 删除文件夹
    deleteClassIfy(event, item) {
      event.stopPropagation();
      this.$confirm("你确定要删除该文件夹吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        await foldersService.myFolderDeleteFolder({ id: item.id });
        await this.queryMyFolderFilesTree();
        if (this.curFolderMenu.id === item.id) {
          this.curFolderMenu = {};
          await this.queryFolderList();
        }
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    },
    // 创建文件
    async createFile(type) {
      await foldersService.myFolderCreateFile({
        type,
        folderId: this.curFolderMenu?.id || 0
      });
      this.queryFolderList();
      this.$message.success("新增成功");
    },
    /**
     * @description: 点击左侧菜单获取文档列表
     * @param {type} classifyId
     * @return {type}
     */
    selectItem(row) {
      console.log("点击的", row);
      this.curFolderMenu = row;
      this.queryFolderList(1, this.pageModel.pageSize);
    },
    // 请求列表数据接口
    async queryFolderList(pageNo = this.pageModel.pageNo, pageSize = this.pageModel.pageSize) {
      const folderId = this.curFolderMenu?.id || "";
      try {
        this.loading = true;
        const res = await foldersService.queryMyFolderFiles({
          pageSize,
          pageNo,
          folderId,
          filterFields: this.filterData,
          sortField: this.sortData
        });
        this.loading = false;
        this.tableData = res.records;
        this.pageModel = res;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 新增分类成功回调
    async classifySuccess() {
      await this.queryMyFolderFilesTree();
      await this.queryFolderList();
      // const parentNode = this.$refs.filtmenu.$children[0].getNode(resp.parentId);
      // console.log(parentNode);
      // this.$refs.filtmenu.$children[0].append(resp, parentNode);
      // TODO 如果需要即时刷新左侧文件夹目录需接口返回当前新增目录的信息 包括id  name  tenantId  目前仅能强刷，强刷可能会导致文件夹选择状态改变
    },
    @request(true, "menuLoading", {
      subTitle: "正在加载中..."
    })
    setTreeIconColor(item) {
      const fileIconColorList = ["#f8bf23", "#169edc"];
      item.iconColor = item.iconColor || fileIconColorList[randomNum(1, 2)]; // 随机生成文件夹得iconColor颜色
      if (item.nodes.length) {
        item.nodes.forEach((citem) => {
          this.setTreeIconColor(citem);
        });
      }
    },
    // 获取我的文件夹列表树
    async queryMyFolderFilesTree() {
      try {
        const res = await foldersService.queryMyFolderFilesTree();

        this.menuData = res;
      } catch (e) {
        console.error(e);
      }
    },
    // 查询总容量和已使用容量接口
    async queryStorage() {
      try {
        this.loading = true;
        const res = await foldersService.queryMyStorage({

        });
        this.countStorage = res.myStorage;
        this.useStorage = res.useStorage;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 删除文件接口
    async deleteFiles(ids) {
      try {
        this.loading = true;
        await foldersService.deleteFiles({
          ids: ids.join(",")
        });
        this.queryFolderList();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 编辑文件名保存提交接口
    async submitForm() {
      const fileForm = this.$refs.editFileAlert.fileForm;
      try {
        this.loading = true;
        await foldersService.editFileName({
          id: fileForm.fileId,
          fileName: fileForm.fileName,
          folderId: fileForm.folderId
        });
        this.queryFolderList();
        this.$message("修改成功");
        this.visiable = false;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 保存手动同步接口
    async saveHandSync(ids, id) {
      try {
        this.loading = true;
        await foldersService.postSyncRule({
          id: ids.join(","),
          ruleId: id
        });
        this.queryFolderList();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description: 删除事件
     * @param {type}
     * @return:
     */
    deleteFile(ids) {
    console.log(234);
      if (!ids.length) {
        this.$message({
          message: "请选择要删除的文件！",
          type: "warning"
        });
      } else {
        this.$confirm("确定删除已选择的条数据？", "", {
          confirmButtonText: "确定",
          type: "warning",
          showCancelButton: false
        }).then(() => {
          this.deleteFiles(ids);
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        });
      }
    },
    /**
     * 多选事件
     */
    handleSelectChange({ selection }) {
      this.selIds = selection.map((item) => item.id);
      this.selRow = selection;
    },
    /**
     * @description:多选手动同步
     * @param {type}
     * @return:
     */
    multipleSync(item) {
      if (this.selRow.length === 0) {
        this.$message({
          message: "请先选择需要手动同步的附件！",
          type: "warning"
        });
      } else {
        if (item) {
          this.formData = { ...item };
        } else {
          this.formData = {};
        }
        this.$refs.handSyncAlert.visiable = true;
      }
    },
    // 写邮件
    entryEmail() {
      const docIds = this.selIds.join();
      console.log(this.selRow);
      let flg;
      this.selRow.forEach((b) => {
          if (Number(b.sourceType) === 2) {
              flg = true;
          }
      });
      if (flg) {
          this.$message.warning("业务建模映射生成的文件在此处不能映射生成邮件附件，请重新选择！");
          return;
      }
      window.open(`/user/mailbox/mailSend?doc=${docIds}`, "_black");
    },
    postHandSync(v) {
      this.saveHandSync(this.selIds, v);
    },
    /**
     * @description:多选删除
     * @param {type}
     * @return:
     */
    multipleDeleteFile() {
      this.deleteFile(this.selIds);
    },
    /**
     * @description:多选下载
     * @param {type}
     * @return:
     */
    multipleDownload() {
      if (this.selRow.length === 0) {
        this.$message({
          message: "请选择下载文件！",
          type: "warning"
        });
      } else {
        this.download(this.selRow);
      }
    },

    /**
     *@description 翻页事件
     * @param {pageNo,pageSize}
     * @return:
     */
    handlePageChanged({ pageNo, pageSize }) {
      // 调用查询列表数据方法
      this.queryFolderList(pageNo, pageSize);
    },
    /**
     * 表格筛选事件
     */
    handleFilterSortChange(data) {
      this.sortData = data.sortData;
      this.filterData = data.filterData;
      this.queryFolderList();
    },

    /**
     * 表格按钮
     */
    handleButtonClick(row, index, btnData) {
      const { id } = btnData;
      const { fileName, fileUrl } = row;
      if (id === "edit") {
        // 编辑按钮
        this.isSingle = false;
        this.editFile(row);
      } else if (id === "download") {
        // 下载按钮
        this.download([row]);
      } else if (id === "delete") {
        // 删除按钮
        this.deleteFile([row.id]);
      } else if (id === "editOnline") {
        // 在线编辑
        const suffix = this.getSuffix(fileName);
        openOfficeOnline(2, suffix, fileUrl);
      }
    },

    /**
     * 编辑文件
     */
    editFile(item) {
      if (item) {
        this.fileFormItem = { ...item };
      } else {
        this.isSingle = true;
        this.fileFormItem = {};
      }
      this.$refs.editFileAlert.visiable = true;
    },

    /**
     * @description: 上传文件
     * @param {type}
     * @return:
     */
    uploadFile() {
      this.$refs.uploadFileAlert.visiable = true;
    },
    /**
     * @description: 分享
     * @param {type}
     * @return:
     */
    shareFile() {
      this.$refs.shareFileAlert.visiable = true;
    },
    /**
     * @description: 获取下载地址
     * @param {filecode, type, filename}
     * @return:fileUrl
     */
    getFileUrl(filecode, type, filename) {
      let fileUrl = ""; // 文件地址
      const name = encodeURIComponent(filename) || "";
      const token = this.$urlParam;
      if (type === "000") {
        fileUrl = `${token.showImageServer}?token=${token.showImageToken}&ts=${token.imgTs}&fileCode=${filecode}&appKey=${token.appKey}&attachmentName=${name}`;
      } else {
        fileUrl = `${token.getFileServer}?token=${token.getFileToken}&ts=${token.ts}&fileCode=${filecode}&appKey=${token.appKey}&attachmentName=${name}`;
      }
      return fileUrl;
    },
    /**
     * @description: 下载
     * @param {row}
     * @return:
     */
    download(row) {
      const _this = this;
      if (row.length > 10) {
        this.$message({
          message: "一次最多下载10个文件！",
          type: "warning"
        });
      }
      row.forEach(async(item) => {
        console.log(item);
        if (item.sourceType === 2) {
          await downFileApi.batchUpLoadFileZip({ id: item.id, type: 2 });
        } else {
          // 定义文件类型及跳转url变量
          let type = "";
          if (item.fileUrl.includes(".gif") || item.fileUrl.includes(".png") || item.fileUrl.includes(".jpg") || item.fileUrl.includes(".bmp")) {
            type = "000"; // 图片类型总和
          } else {
            type = "001"; // 文件类型总和
          }
          const src = _this.getFileUrl(item.fileUrl, type, item.fileName);
          xhrDownload(src, (error) => {
            this.$message.error(error);
          });
        }
      });
    },
    /**
     * @description: 同步规则
     * @param {type}
     * @return:
     */
    syncRule() {
      this.$router.push({ path: "/my-folder/syncRule" });
    },
    getFile() {
      console.log("收到调用");
      this.queryFolderList();
    }
  }
};
</script>

<style lang="scss" scoped>
.tiling-main {
  position: relative;
  .en-title-card {
    overflow: hidden;
  }
  .pull-left {
    float: left;
  }
  .pull-right-top {
    float: right;
    .add-folder{
      cursor: pointer;
    }
  }
  .col-tit {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }

  .equal-side {
    width: 285px;
    border-radius: 5px;
    background: #fff;
    height: 100%;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    .equal-sidetopbar {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      border-bottom: 1px solid #e8ecf2;

      .en-icon {
        color: #3e90fe;
      }
    }
  }
  .equal-topbar {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #e8ecf2;
  }
  .equal-right {
    border-radius: 5px;
    position: relative;
    background: #fff;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 295px);
    & /deep/ .en-title-card-left-component{
      margin: 0;
    }

  }
  .side-list{
    border: 1px solid #eaebef;
    height:100%;
    border-radius:5px;
    & /deep/ .en-icon{
      color:#169edc!important
    }
    & /deep/ .is-expanded,/deep/ .is-current {
      .en-icon{
        color:#f8bf23!important
      }
    }
  }
   .equal-sidecentbar {
    padding:20px;
    padding-bottom: 70px;
    overflow-y: auto;
    height: 100%;
    /deep/ .el-tree-node__children{
      overflow: unset;
    }
    /deep/ .custom-tree-node{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            .custom-file-icon{
              margin-left: 2px;
              margin-right: 13px;
            }
            .name-input{
                flex: 1;
            }
            .label{
              flex: 1;
            }
            .btns{
              width: 30px;
              flex: 0;
              color: inherit;
            }
            .btns-icon {
                width: 16px;
                height: 16px;
                color: #3e90fe!important;
                display: none;
            }

            .btns-list-container {
              position: absolute;
              right: -13px;
              top: 16px;
              z-index: 9999;
              .btns-list {
                width: 120px;
                background-color: #ffffff;
                border: 1px solid #E8ECF2;
                box-shadow: 0 5px 5px 0 rgba(236, 236, 236, 0.75);
                border-radius: 4px;
                margin-top: 17px;

                .btns-item {
                  color: #636C78;
                  cursor: pointer;
                  line-height: 32px;
                  font-size: 12px;
                  padding-left: 13px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  &:hover {
                    background-color: #3e90fe;
                    color: #ffffff;
                  }
                }
              }
            }
    }
    /deep/ .el-tree-node__content:hover{
      .custom-tree-node .btns{
        visibility: visible;
      }
      .btns-icon {
        display: block!important;
      }
    }
  }
  .btn-group {
    .en-icon,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    .en-icon {
      margin-right: 5px;
    }
    div {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      cursor: pointer;
    }
    .dropdown-add{
      margin: 0;
      font-size: 12px;
      & /deep/ .el-dropdown{
        margin-right: 0;
      }
      .add-btn{
        font-size: 12px;
        margin: 0;
      }
    }
  }
  .table-wrap {
    margin-top: 10px;
    height: calc(100% - 100px);
    .en-icon {
      display: inline-block;
      vertical-align: middle;
    }
    .file-title{
      cursor: pointer;
    }
  }
  .volume {
    .tits,
    .max-volume {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
    }
    .el-progress {
      display: inline-block;
      vertical-align: middle;
      width: 100px;
      margin: 0 10px;
    }
  }

}
.dropdown-list{
  & /deep/  .el-dropdown-menu__item{
    width:auto;
    &:hover{
      background-color: #4795df;
      color: #fff;
    }
  }
}
/deep/ .pagination{
  padding-left: 20px;
}
 .selection {
    font-size: 12px;
    color: #606060;
    span {
      color: #4695DF;
    }
    .en-button {
      margin-left: 10px;
      padding-bottom: 2px;
    }
  }
</style>
