var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "edit-file",
      attrs: { visible: _vm.visiable, title: "编辑文件", width: "700px" },
      on: {
        "update:visible": function ($event) {
          _vm.visiable = $event
        },
        close: function ($event) {
          _vm.visiable = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-form",
            {
              ref: "fileForm",
              attrs: {
                model: _vm.fileForm,
                "label-width": "80px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  class: _vm.isSingle ? "show" : "",
                  attrs: { label: "文件名称" },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20", placeholder: "输入文件名称" },
                    on: {
                      focus: function ($event) {
                        return _vm.focus($event)
                      },
                    },
                    model: {
                      value: _vm.fileForm.fileName,
                      callback: function ($$v) {
                        _vm.$set(_vm.fileForm, "fileName", $$v)
                      },
                      expression: "fileForm.fileName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属文件夹", size: "small" } },
                [
                  _c("en-select", {
                    attrs: {
                      mode: "tree",
                      "data-mode": "data",
                      placeholder: "请选择",
                      props: _vm.defaultProps,
                      data: _vm.superiorList,
                      "check-limit": 1,
                      "expand-on-click-node": false,
                      "check-on-click-node": true,
                    },
                    on: { change: _vm.selectChange },
                    model: {
                      value: _vm.superiorId,
                      callback: function ($$v) {
                        _vm.superiorId = $$v
                      },
                      expression: "superiorId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "en-button",
            {
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }